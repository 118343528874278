module.exports = {
  env: 'production',
  apiUrl: 'https://koderia.org/rest/koderia',
  adminUrl: 'https://admin.koderia.sk',
  baseUrl: 'https://koderia.sk',
  firebaseRegion: 'us-central1',
  firebaseConfig: {
    apiKey: 'AIzaSyDK5GBRnkV5jv9jOcIOjB97uOk1h0KXlw4',
    authDomain: 'koderia.sk',
    databaseURL: 'https://koderikoderom-365dd.firebaseio.com',
    projectId: 'koderikoderom-365dd',
    storageBucket: 'koderikoderom-365dd.appspot.com',
    messagingSenderId: '678888712592',
    appId: '1:678888712592:web:08adb12edbf479bc',
  },
  sentry: {
    dsn: 'https://f9eda32c2f413a7754ddbf6789b9a6b6@o4508737341947904.ingest.de.sentry.io/4508761343983696',
    environment: 'production',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },
}
