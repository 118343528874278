<template>
  <v-app>
    <div class="tw-text-gray-700 tw-bg-gray-100 tw-font-sans tw-h-full">
      <component :is="layout">
        <v-fade-transition mode="out-in">
          <router-view :key="$route.name" />
        </v-fade-transition>
      </component>

      <ka-prompt />
      <verification-dialog />
    </div>
  </v-app>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import Cookies from 'js-cookie'

import KaPrompt from '@web/components/ka-prompt'

// Register Layout
import CvLayout from '@web/layouts/cv.vue'
import AuthLayout from '@web/layouts/auth.vue'
import FrameLayout from '@web/layouts/frame.vue'
import DefaultLayout from '@web/layouts/default.js'
import VerificationDialog from '@web/components/verification-dialog.vue'
import { isEmpty } from 'lodash'
import { setBearerToken } from '@web/api/config'

export default {
  name: 'App',
  components: {
    VerificationDialog,
    KaPrompt,
  },
  data: () => ({
    init: false,
  }),
  methods: {
    getParameter(theParameter) {
      var params = window.location.search.substr(1).split('&')
      for (var i = 0; i < params.length; i++) {
        var p = params[i].split('=')
        if (p[0] === theParameter) {
          return decodeURIComponent(p[1])
        }
      }
      return false
    },
    processCookies() {
      let url_src = this.getParameter('utm_source')
      let fb = this.getParameter('fbclid')
      let url_mdm = this.getParameter('utm_medium')
      let url_cpn = this.getParameter('utm_campaign')

      let existingCookie = Cookies.get('cookie_utms')

      // check if cookie doesn't already exist
      if (existingCookie == null || existingCookie === '') {
        let newCookie = {
          source: fb ? 'facebook' : 'noone',
          medium: 'direct',
          campaign: 'none',
        }

        // check if utm stuff is present
        if ((url_src !== false && fb !== false) || url_mdm !== false || url_cpn !== false) {
          newCookie.source = url_src ? url_src : 'facebook'
          newCookie.medium = url_mdm
          newCookie.campaign = url_cpn
        }
        // else {} utm stuff is not present => user came directly to koderia.sk URL
        Cookies.set('cookie_utms', newCookie, {
          expires: 120,
        })
      }
      // else{} cookie did exist before => we dont change a thing
    },
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout || 'default'

      switch (layout) {
        case 'auth':
          return AuthLayout
        case 'cv':
          return CvLayout
        case 'frame':
          return FrameLayout
        default:
          return DefaultLayout
      }
    },
  },
  created() {
    this.$store.dispatch('ENUMS/loadEnums')

    this.processCookies()

    firebase.auth().onAuthStateChanged(() => {
      this.$store.dispatch('AUTH/authStateChange')
    })

    firebase.auth().onIdTokenChanged(async user => {
      if (user) {
        const token = await user.getIdToken()

        setBearerToken(token)
      }
    })
  },
  watch: {
    $route(to) {
      // if (to.name == from.name || to.path.includes("/praca/")) {
      //     return;
      // }

      if (
        [
          this.$data.$ROUTE_NAMES.HOME,
          this.$data.$ROUTE_NAMES.PROJECT_INDEX,
          this.$data.$ROUTE_NAMES.BLOG_INDEX,
        ].includes(to.name) && !isEmpty(to.query)
      ) {
        return
      }

      if (!to.hash) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }

      if (!this.init && to.name !== this.$data.$ROUTE_NAMES.CV_SHOW) {
        this.$store.dispatch('EVENTS/getAllEvents')
        this.$store.dispatch('NEWSFEED/fetchNext')

        this.init = true
      }
    },
  },
}
</script>

<style lang="scss">
@import "@web/sass/global.scss";
@import "@web/sass/variables.scss";
</style>
